import { Popover, PopoverButton, PopoverPanel, Transition } from "@headlessui/react";
import {
  BellAlertIcon as BellAlertIconOutline,
  BellIcon as BellIconOutline,
  CheckCircleIcon,
  Cog6ToothIcon,
  InboxIcon,
} from "@heroicons/react/24/outline";
import {
  ArrowRightIcon,
  BellAlertIcon as BellAlertIconSolid,
  BellIcon as BellIconSolid,
} from "@heroicons/react/24/solid";
import { ProjectStatus } from "@prisma/client";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { formatDistance } from "date-fns";
import { useRouter } from "next/navigation";
import { Fragment, useEffect, useState } from "react";
import { webAssetsCdnBaseUrl } from "../constants";
import { markAllAsRead } from "./feed-action";
import { useOnScreen } from "@/helpers/hooks/use-on-screen";
import { classNames } from "@/helpers/tailwind-class";
import { apiClient } from "@/server/init";

export function Feed() {
  const router = useRouter();

  const [isFeedOpen, setIsFeedOpen] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const notifQuery = useInfiniteQuery({
    queryKey: ["notification-details"],
    queryFn: async ({ pageParam }) => {
      const result = await apiClient.notifications.getMany({
        query: { offset: pageParam.offset },
        fetchOptions: { priority: "low" },
      });

      return result.body.items;
    },
    initialPageParam: { offset: 0 },
    getNextPageParam: (lastPage, _allPages, lastPageParam, _allPageParams) => {
      if (lastPage?.length === 0) return;

      return { offset: (lastPageParam?.offset ?? 0) + (lastPage?.length ?? 0) };
    },
  });

  const hasInProgressJobs = (notifQuery.data?.pages.flatMap((page) => page) ?? []).some(
    (n) => n?.status === "PENDING" || n?.status === "STARTING"
  );

  const notifCountQuery = useQuery({
    queryKey: ["notification-unseen-count"],
    queryFn: () => apiClient.notifications.getUnseenCount({ fetchOptions: { priority: "low" } }),
    refetchInterval: hasInProgressJobs ? 20000 : Infinity,
    refetchOnWindowFocus: true,
  });
  const unseenCount = notifCountQuery.data?.body.count || 0;

  function handleSeen() {
    const unseenIds = (notifQuery.data?.pages
      .flatMap((page) => page)
      .filter((item) => item.state === "unseen")
      .map((item) => item.notificationId)
      .filter(Boolean) ?? []) as string[];

    if (unseenIds.length === 0) return Promise.resolve({ type: "success", data: true });
    return apiClient.notifications.updateMany({ body: { state: "seen", ids: unseenIds } });
  }

  const { setRef: loadMoreRef, isVisible: shouldLoadMore } = useOnScreen(0.7);
  useEffect(() => {
    if (shouldLoadMore) {
      notifQuery.fetchNextPage();
    }
  }, [shouldLoadMore]);

  useEffect(() => {
    if (isFeedOpen) {
      handleSeen()
        .then(() => notifCountQuery.refetch())
        .catch(console.error);
    }
  }, [isFeedOpen]);

  const data = notifQuery.data?.pages.flatMap((page) => page) ?? [];

  const renderIcon = (open: boolean) => {
    if (open) {
      if (unseenCount > 0) {
        return <BellAlertIconSolid className="h-6 w-6" aria-hidden="true" />;
      }

      return <BellIconSolid className="h-6 w-6" aria-hidden="true" />;
    }

    if (unseenCount > 0) {
      return <BellAlertIconOutline className="h-6 w-6" aria-hidden="true" />;
    }

    return <BellIconOutline className="h-6 w-6" aria-hidden="true" />;
  };

  const statusBadge: Record<keyof typeof ProjectStatus, string> = {
    DRAFT: "",
    PENDING: "badge-info",
    STARTING: "badge-info",
    COMPLETE: "badge-success",
    ERROR: "badge-error",
    CANCELED: "",
  };

  return (
    <div className="block max-w-sm">
      <Popover className="relative">
        {({ open }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            setIsFeedOpen(open);
          }, [open]);

          return (
            <>
              <PopoverButton
                className={` ${open ? "text-white" : "text-white/90"} group inline-flex items-center rounded-md px-1 py-1 text-base font-medium hover:text-white focus:outline-none`}
              >
                <div className="indicator">
                  {unseenCount > 0 && (
                    <span
                      className={classNames(
                        "badge indicator-item badge-error badge-xs rounded-full",
                        open ? "bg-error" : "bg-error/80"
                      )}
                    >
                      {unseenCount > 9 ? "9+" : unseenCount}
                    </span>
                  )}
                  {renderIcon(open)}
                </div>
                <span className="sr-only">Feed</span>
              </PopoverButton>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <PopoverPanel className="fixed right-0 z-10 mt-3 w-screen max-w-sm transform px-2 sm:right-4 sm:px-0 lg:max-w-sm">
                  {({ close }) => (
                    <div className="h-96 overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                      <div className="relative h-full overflow-hidden rounded-lg border border-info/80 bg-slate-900">
                        <div className="flex h-16 items-center justify-between p-4 shadow-md">
                          <h3 className="text-base font-medium text-white sm:text-lg">
                            Notifications
                          </h3>

                          <div className="flex">
                            <button
                              className="btn btn-ghost btn-xs flex items-center font-thin"
                              onClick={() =>
                                markAllAsRead()
                                  .then(() => {
                                    notifQuery.refetch();
                                    notifCountQuery.refetch();
                                    return;
                                  })
                                  .catch(console.error)
                              }
                            >
                              Mark all as read{" "}
                              <CheckCircleIcon className="h-3 w-3" aria-hidden="true" />
                            </button>

                            <button
                              className="btn btn-circle btn-ghost btn-xs flex items-center font-thin"
                              onClick={() => {
                                close();
                                router.push("/settings");
                              }}
                            >
                              <Cog6ToothIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                          </div>
                        </div>

                        {data.length > 0 ? (
                          <div className="h-80 divide-y divide-solid divide-slate-600 overflow-y-auto">
                            {data.map((item) => {
                              return (
                                <button
                                  key={item.id}
                                  onClick={() => {
                                    close();
                                    router.push(`/dashboard/videos/${item.id}`);

                                    if (item.notificationId) {
                                      apiClient.notifications.updateMany({
                                        body: { ids: [item.notificationId], state: "read" },
                                      });
                                    }
                                  }}
                                  className="group relative flex w-full items-center px-4 py-4 text-left transition duration-150 ease-in-out hover:bg-gray-800 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                >
                                  {["seen", "unseen"].includes(item.state ?? "") && (
                                    <div className="absolute left-2 top-2 h-2 w-2 rounded-full bg-info"></div>
                                  )}
                                  <div className="avatar">
                                    <div className="w-14 rounded-full">
                                      <img
                                        src={`${webAssetsCdnBaseUrl}/notifications/${item.type.replaceAll("_", "-").toLowerCase()}.png`}
                                        alt="Avatar"
                                        width={60}
                                        height={60}
                                      />
                                    </div>
                                  </div>
                                  <div className="ml-4 block space-y-2">
                                    <p
                                      className={classNames(
                                        "badge badge-outline badge-sm capitalize opacity-80",
                                        statusBadge[item.status]
                                      )}
                                    >
                                      {item.status.toLowerCase()}
                                    </p>
                                    <blockquote className="block break-all border-l-2 border-slate-600 pl-2 text-sm font-medium">
                                      {item.name ?? "Unnamed project"}
                                    </blockquote>
                                    <p className="text-xs text-gray-500">
                                      {formatDistance(
                                        new Date(item.completedAt ?? item.updatedAt),
                                        currentTime,
                                        { addSuffix: true }
                                      )}
                                    </p>
                                  </div>
                                  {/* TODO: add ability to archive notifications */}
                                  {/* <div
                                    className="btn btn-circle btn-ghost btn-xs ml-auto hidden items-center font-thin group-hover:flex"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      alert("hi");
                                    }}
                                  >
                                    <XMarkIcon className="h-3 w-3" aria-hidden="true" />
                                  </div> */}
                                </button>
                              );
                            })}
                            {notifQuery.hasNextPage && (
                              <div className="flex justify-center py-1">
                                <div
                                  className="loading loading-spinner loading-md"
                                  ref={loadMoreRef}
                                ></div>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="flex h-80 w-full flex-col items-center justify-center gap-2">
                            <InboxIcon className="h-8 w-8 text-gray-400" aria-hidden="true" />

                            <div>No new notifications</div>

                            <button
                              className="btn btn-info btn-sm"
                              onClick={() => {
                                close();
                                router.push("/create");
                              }}
                            >
                              Create something cool{" "}
                              <ArrowRightIcon className="h-4 w-4" aria-hidden="true" />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </PopoverPanel>
              </Transition>
            </>
          );
        }}
      </Popover>
    </div>
  );
}
