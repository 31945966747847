"use client";

import type { StaticImageData } from "next/image";
import NextImage from "next/image";
import React from "react";
import type { Props as MediaProps } from "../types";

const cssVariables = {
  breakpoints: {
    "3xl": 1920,
    "2xl": 1536,
    xl: 1280,
    lg: 1024,
    md: 768,
    sm: 640,
  },
};

const { breakpoints } = cssVariables;

// A base64 encoded image to use as a placeholder while the image is loading
const placeholderBlur =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAulBMVEUlJ00nKU4qLFEpK1EjJUtOUG58fpQkJkx6e5JRU3Gam6zb2+E8PmA5O13Y2d+en69CQ2Td3uP8+/t/gJY7PF7c3OLi4uZERmaHiJ37+/v+/v3MzdU0NlmUlKf9/f2LjKA6PF7W193s7e/R0tn39/hub4hISWnp6eza2uA9PmBSU3G2t8OJip5lZ4H09PW+v8owMlaIiZ23uMRVVnQnKU8pK1AuMFTCwszFxc8pKlAiJEtsbodwcYr////krgOEAAAAAWJLR0Q90G1RWQAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB+gHBBIKN1ZxQpMAAABuSURBVBjTY2AgEzAyMTAwMyL4LKxsDOwcnCxwAXYubh5ePn6EAgFBIWERUTFxmBIWCUkpaRlZOXmoALuCopKyiqqauoYmO0SBlraOrp6+gaGRMVSJiSmDmbmFGYOlCcJiK2sbK1SnMTGR6ykGBgC2XwgBNaTwtQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyNC0wNy0wNFQxODoxMDo1NSswMDowMNJo/iYAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjQtMDctMDRUMTg6MTA6NTUrMDA6MDCjNUaaAAAAV3pUWHRSYXcgcHJvZmlsZSB0eXBlIGlwdGMAAHic4/IMCHFWKCjKT8vMSeVSAAMjCy5jCxMjE0uTFAMTIESANMNkAyOzVCDL2NTIxMzEHMQHy4BIoEouAOoXEXTyQjWVAAAAAElFTkSuQmCC";

export function ImageMedia(props: MediaProps) {
  const {
    alt: altFromProps,
    fill,
    imgClassName,
    priority,
    resource,
    size: sizeFromProps,
    src: srcFromProps,
    loading: loadingFromProps,
  } = props;

  let width: number | undefined;
  let height: number | undefined;
  let alt = altFromProps;
  let src: StaticImageData | string = srcFromProps || "";

  if (!src && resource && typeof resource === "object") {
    const {
      alt: altFromResource,
      filename: fullFilename,
      height: fullHeight,
      url,
      width: fullWidth,
    } = resource;

    width = fullWidth!;
    height = fullHeight!;
    alt = altFromResource || "";

    src = `${url}`;
  }

  const loading = loadingFromProps || (!priority ? "lazy" : undefined);

  // NOTE: this is used by the browser to determine which image to download at different screen sizes
  const sizes = sizeFromProps
    ? sizeFromProps
    : Object.entries(breakpoints)
        .map(([, value]) => `(max-width: ${value}px) ${value * 2}w`)
        .join(", ");

  return (
    <picture>
      <NextImage
        alt={alt || ""}
        className={imgClassName}
        fill={fill}
        height={!fill ? height : undefined}
        placeholder="blur"
        blurDataURL={placeholderBlur}
        priority={priority}
        quality={100}
        loading={loading}
        sizes={sizes}
        src={src}
        width={!fill ? width : undefined}
      />
    </picture>
  );
}
